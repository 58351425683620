import React from 'react'
import Modal from 'react-modal'
import Video from 'components/Video'
import * as styles from './styles.module.scss'

const Component = ({ content, locale }) => {
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    content: {
      position: 'relative',
      inset: 0,
      border: 'none',
      backgroundColor: 'transparent',
      padding: 0,
      borderRadius: 0,
      cursor: 'default',
      width: '100%',
      pointerEvents: 'none',
      left: 0, // android wechat browser
    },
  }

  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
  Modal.setAppElement('#___gatsby')

  let subtitle
  const [modalIsOpen, setIsOpen] = React.useState(false)

  function openModal() {
    setIsOpen(true)
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00'
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <div className={styles.component}>
      <div className={styles.open} onClick={openModal} />
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        <div className={styles.close} onClick={closeModal}>
          <div className={styles.inner} />
        </div>
        <div className={styles.video}>
          <div className={styles.wrap}>
            <Video
              content={content}
              settings={{
                controls: true,
                autoplay: true,
              }}
              locale={locale}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Component
